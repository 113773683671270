import React, {createContext} from 'react'

const initialState = {}
export const ContextState = createContext(initialState);


const reducer = (state, action) => {
    switch (action.type) {
        case "setState":
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const ContextStateProvider = ({...props}) => {

    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
        <ContextState.Provider value={{
            state,
            dispatch
        }}>
            {props.children}
        </ContextState.Provider>
    )
}